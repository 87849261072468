<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">
            Package & Promotion
          </h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create Package
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-diglog-update
      :show="isUpdate"
      :status="action.status === 'Active' ? 'Display' : 'Not Display'"
      isActiveText="Display"
      :close="closePopup"
      :confirm="confirmUpdate"
    />
    <v-diglog-confirm
      :show="isDelete"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :buttonText="buttonConfirmText"
      :confirm="confirmDelete"
    />

    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <preview
      :show="isPreview"
      :close-preview="closePreview"
      :orgs="previewOrgs"
      :data-object="packageObject"
      :sub-package-list="subPackageList"
    />

    <!-- Data table -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="4" t-data="search-panel">
            <search
              :search="searchPhase"
              label="Search by Title"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col cols="12" sm="6"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center"
            t-data="filter-panel"
          >
            <filter-dropdown @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          :loading="isLoading"
          :options.sync="options"
          :headers="table.headers"
          :items="packagesList"
          :items-per-page="table.pageSize"
          :server-items-length="totalPackages"
          :footer-props="{'items-per-page-options': table.itemPerPages}"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.content="{item}" style="width: 130px">
            <tooltip-title :contents="item.contents">
              <template v-slot:content>
                <v-list-item-content
                  class="d-inline-block text-truncate"
                  style="width: 130px"
                >
                  <v-list-item-title>
                    {{
                      item.contents[0].code === 'th'
                        ? item.contents[0].title
                        : item.contents[1].title
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      item.contents[0].code === 'en'
                        ? item.contents[0].title
                        : item.contents[1].title
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </tooltip-title>
          </template>
          <template v-slot:item.total_sub_package="{item}">
            <v-chip
              v-if="isPackageGroup(item.package_type)"
              class="ma-2 d-flex justify-center sub-package-chip"
              color="success200Color"
              text-color="info800Color"
            >
              <div>
                <v-icon size="20" color="success600Color" left>
                  mdi-check-circle
                </v-icon>
                {{ item.total_sub_package }}
              </div>
            </v-chip></template
          >
          <template v-slot:item.orgs="{item}">{{
            listBranch(item.orgs)
          }}</template>
          <template v-slot:item.display_type="{item}">{{
            item.display_type in channelTextItems
              ? channelTextItems[item.display_type]
              : ''
          }}</template>
          <template v-slot:item.price="{item}">
            <div v-if="isPackageGroup(item.package_type)">
              {{
                groupPriceText(
                  item.total_sub_package,
                  item.min_price,
                  item.max_price
                )
              }}
            </div>
            <div v-else>฿ {{ convertNumToComma(item.price) }}</div>
          </template>
          <template v-slot:item.is_publish="{item}">
            <publish-status-chip
              :is-publish="item.is_publish"
            ></publish-status-chip>
          </template>
          <template v-slot:item.validity_start_date="{item}">{{
            !!item.validity_start_date ? dateFmt(item.validity_start_date) : ''
          }}</template>
          <template v-slot:item.validity_end_date="{item}">{{
            !!item.validity_end_date ? dateFmt(item.validity_end_date) : ''
          }}</template>
          <template v-slot:item.update_date="{item}">{{
            !!item.update_date ? datetimeFmt(item.update_date) : ''
          }}</template>
          <template v-slot:item.update_by_user="{item}"
            ><username-status-text :user="item.update_by_user"
          /></template>
          <template v-slot:item.discount="{item}">
            <div class="d-flex justify-center">
              <v-img
                t-data="check-icon-image"
                aspect-ratio="1"
                v-show="item.discount > 0 ? true : false"
                max-height="24"
                max-width="24"
                src="/images/check-icon.png"
              ></v-img>
            </div>
          </template>
          <template v-slot:item.is_for_sales="{item}">{{
            listAvailability(item.orgs)
          }}</template>
          <template v-slot:item.status="{item}">
            <v-switch
              t-data="status-switch"
              color="primary200Color"
              v-model="item.status"
              :disabled="!hasPermEditByOrgs(item.orgs)"
              @change="event => updateStatus(item.id, event)"
              class="mb-0 pb-0 custom-switch"
            ></v-switch>
          </template>
          <template v-slot:item.action="{item}">
            <div>
              <v-icon
                v-if="hasPermCreateByOrgs(item.orgs)"
                t-data="duplicate-action"
                class="px-2 action-icon"
                @click="duplicate(item.id)"
                >mdi-plus-box-multiple-outline</v-icon
              >
              <v-icon
                v-else
                t-data="empty-icon"
                class="px-2 action-icon transparent-icon"
              ></v-icon>
              <v-icon
                t-data="preview-action"
                class="px-2 action-icon"
                @click="preview(item)"
                >mdi-magnify</v-icon
              >
              <v-icon
                t-data="edit-action"
                class="px-2 action-icon"
                @click="rowClick(item)"
                >mdi-pencil-outline</v-icon
              >
              <v-icon
                v-if="hasPermDeleteByOrgs(item.orgs)"
                t-data="delete-action"
                class="px-2 action-icon"
                @click="showDeletePopup(item.id)"
                >mdi-trash-can-outline</v-icon
              >
              <v-icon
                v-else
                t-data="empty-icon"
                class="px-2 action-icon transparent-icon"
              ></v-icon>
            </div>
          </template>
        </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
  convertDateBirthDate,
  hasPermision,
  searchHandler,
  convertSelectedData,
  convertIntegerToCommaNumber,
  snackbarSuccessContent,
  snackbarFailContent,
  hasPermissionsByOrgs,
  convertDate
} from '@/helper/helper'
import Confirm from '@/components/AlertConfirm'
import FilterDropdown from '@/views/package/FilterDropdown'
import Popup from './UpdateStatusPopup'
import PublishStatusChip from '@/components/PublishStatusChip'
import Search from '@/components/Search'
import SnackBar from '@/components/SnackBar'
import TooltipTitle from '@/components/TooltipTitle'
import {actionEnum} from '@/constants/constants'
import UsernameStatusText from '@/components/UsernameStatusText'
import Preview from '@/components/package/Preview'
import {packageTypeEnum, displayTypeText} from '@/constants/package'

export default {
  components: {
    'v-diglog-confirm': Confirm,
    'v-diglog-update': Popup,
    FilterDropdown,
    Preview,
    PublishStatusChip,
    Search,
    SnackBar,
    TooltipTitle,
    UsernameStatusText
  },
  data() {
    return {
      pageName: 'Package and promotion',
      heightOptions: {
        header: 312,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      previewOrgs: [],
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'created',
      updateType: 'single',
      promotionOnly: 'all',
      buttonConfirmText: 'Delete',
      subTitle:
        'This content will be deleted in both Thai and English.\nAre you sure you want to delete this content?',
      searchPhase: '',
      isUpdate: false,
      options: {},
      action: {
        status: ''
      },
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id',
            width: 70
          },
          {
            text: 'Title',
            align: 'left',
            value: 'content',
            sortable: false,
            width: 184
          },
          {
            text: 'Sub Package',
            align: 'center',
            value: 'total_sub_package',
            sortable: false,
            width: 74
          },
          {
            text: 'Branch',
            align: 'left',
            value: 'orgs',
            sortable: false,
            width: 142
          },
          {
            text: 'Department',
            align: '',
            value: 'department_name_en',
            sortable: false,
            width: 160
          },
          {
            text: 'Channel',
            align: '',
            value: 'display_type',
            sortable: true,
            width: 160
          },
          {
            text: 'Sale Price (Baht)',
            align: 'right',
            value: 'price',
            sortable: true,
            width: 170
          },
          {
            text: 'Publish Status',
            align: 'center',
            value: 'is_publish',
            sortable: false,
            width: 166
          },
          {
            text: 'Validity Start Date',
            align: 'left',
            value: 'validity_start_date',
            sortable: true,
            width: 180
          },
          {
            text: 'Validity End Date',
            align: 'left',
            value: 'validity_end_date',
            sortable: true,
            width: 180
          },
          {
            text: 'Updated Date/Time',
            align: 'left',
            value: 'update_date',
            sortable: false,
            width: 196
          },
          {
            text: 'Updated By',
            align: 'left',
            value: 'update_by_user',
            sortable: false,
            width: 162
          },
          {
            text: 'Discount',
            align: 'center',
            value: 'discount',
            sortable: false,
            width: 120
          },
          {
            text: 'Availability',
            align: 'left',
            value: 'is_for_sales',
            sortable: false,
            width: 150
          },
          {
            text: 'Status',
            align: 'center',
            value: 'status',
            sortable: false,
            width: 100
          },
          {
            text: 'Actions',
            align: 'center',
            value: 'action',
            sortable: false,
            width: 165
          }
        ]
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          href: '/'
        },
        {
          text: 'Package & Promotion',
          disabled: true,
          link: true,
          href: '/'
        }
      ],
      channelTextItems: displayTypeText
    }
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('subPackage', ['subPackageList']),
    ...mapState('packages', [
      'orgList',
      'isDelete',
      'packagesList',
      'packageObject',
      'isLoading',
      'totalPackages',
      'updateIdList',
      'isPreview'
    ])
  },
  created() {
    this.setListOrgInfo()
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    hasPermCreate() {
      return hasPermision('CanAddPackage')
    },
    hasPermCreateByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanAddPackage', codenames)
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditPackage', codenames)
    },
    hasPermDeleteByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanDeletePackage', codenames)
    },
    create() {
      this.$router.push({name: 'package-create'})
    },
    async preview(row) {
      await this.setPreview(row.id)
      await this.fetchSubPackage(row.id)
      this.previewOrgs = row.orgs.map(org =>
        this.orgList.all.find(ob => ob.codename === org.org_codename)
      )
      this.setShowPreview(true)
    },
    isPackageGroup(type) {
      return type === packageTypeEnum.GROUP
    },
    groupPriceText(totalSubPackage, minPrice, maxPrice) {
      if (totalSubPackage <= 0) {
        return ''
      } else if (minPrice === maxPrice) {
        return `฿ ${this.convertNumToComma(minPrice)}`
      } else {
        return `฿ ${this.convertNumToComma(
          minPrice
        )} - ${this.convertNumToComma(maxPrice)}`
      }
    },
    closePreview() {
      this.setShowPreview(false)
      this.resetState()
      this.resetSubPackageList()
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    listAvailability(orgs) {
      const statusText = status => (status ? 'Available' : 'Unavailable')
      return orgs.map(org => statusText(org.is_for_sales)).join(', ')
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()

      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    async confirmUpdate() {
      this.isUpdate = false
      const isSuccess = await this.update(this.action.status)
      this.selected = []
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    closePopup() {
      this.isUpdate = false
      if (this.updateType !== 'group') {
        this.updateType = ''
        const id = this.updateIdList[0].id
        this.packagesList.map(item => {
          if (item.id === id) {
            item.status = item.status ? false : true
          }
          return item
        })
      }
    },
    dateFmt: date => convertDateBirthDate(date),
    datetimeFmt: date => convertDate(date),
    updateStatus(id, event) {
      if (event) {
        this.action.status = 'Active'
      } else {
        this.action.status = 'Inactive'
      }
      this.updateType = 'single'
      this.setSelectedId([{id}])
      this.isUpdate = true
    },
    textSearch(data) {
      this.searchPhase = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.searchPhase.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    duplicate(item) {
      this.setDuplicateId(item)
      this.$router.push({name: 'package-create'})
    },
    rowClick(pkg) {
      this.$router.push({name: 'package-edit', params: {id: pkg.id}})
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        [data],
        this.orgList.all,
        this.orgList.selected
      )
      this.setPage(1)
      this.options.page = 1
      this.fetch()
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    ...mapActions('subPackage', {
      fetchSubPackage: 'fetch',
      resetSubPackageList: 'resetList'
    }),
    ...mapActions('packages', [
      'deletePkg',
      'fetch',
      'resetList',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDuplicateId',
      'setFieldSort',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setPreview',
      'setSelectedId',
      'setShowPreview',
      'setSort',
      'setTextSearch',
      'update'
    ])
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },

  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>
