<template>
  <quick-preview
    :is-preview="show"
    :show-close-btn="true"
    :display-type="dataObject.display_type"
    @input="closePreview"
  >
    <template v-slot:content-mobile="{lang}">
      <mobile-preview-content
        :lang="lang"
        :orgs="orgs"
        :data-object="dataObject"
        :package-type="dataObject.package_type"
        :sub-package-list="subPackageList"
      />
    </template>
    <template v-slot:content-web="{lang}">
      <website-preview-content
        :lang="lang"
        :orgs="orgs"
        :data-object="dataObject"
        :package-type="dataObject.package_type"
        :sub-package-list="subPackageList"
      />
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/package/QuickPreview'
import MobilePreviewContent from '@/components/package/MobilePreviewContent'
import WebsitePreviewContent from '@/components/package/WebsitePreviewContent'

export default {
  name: 'Preview',
  components: {
    QuickPreview,
    MobilePreviewContent,
    WebsitePreviewContent
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    dataObject: {type: Object},
    orgs: {type: Array},
    subPackageList: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
