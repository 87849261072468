<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-6" style="height: 54px;">
        <v-app-bar class="mobile-app-bar" t-data="mobile-app-bar" dense>
          <v-btn icon>
            <v-icon color="whiteColor">mdi-arrow-left</v-icon>
          </v-btn>
        </v-app-bar>
      </v-col>
      <v-col cols="12" t-data="profile-image" class="py-0">
        <v-img :src="contentObject.thumbnail" max-height="501"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-4">
        <h4 class="mt-0 card-title" t-data="info-title">
          {{ contentObject.title }}
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="!isGroupPackage">
      <v-col cols="12" class="align-end d-flex justify-start py-0">
        <h6 class="primary500Color--text" t-data="discount-price">
          {{
            calDiscountPrice(
              dataObject.discount,
              dataObject.discount_type,
              dataObject.full_price
            ) + ' Baht'
          }}
        </h6>
        <div
          class="sales-price-preview-discount-price body-regular ml-2"
          t-data="full-price"
          v-if="dataObject.discount && dataObject.discount > 0"
        >
          {{ commaNumber(dataObject.full_price) + ' Baht' }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="body-regular secondaryTextColor--text pt-4 pb-0"
        t-data="publish-date"
      >
        <v-icon left light size="18">
          mdi-calendar-month
        </v-icon>
        {{ dateFmt(dataObject.period_start_date) }} -
        {{ dateFmt(dataObject.period_end_date) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="body-regular secondaryTextColor--text pt-2 pb-0"
        t-data="org-name"
      >
        <v-icon left light size="18">
          mdi-office-building
        </v-icon>
        {{ getOrgNameByLang() }}
      </v-col>
    </v-row>
    <sub-package-content
      v-if="isGroupPackage"
      :lang="lang"
      :channel="displayType.MOBILE"
      :sub-package-list="subPackageList" />
    <v-divider :class="{'mb-4': true, 'mt-4': !hasSubPackage}"></v-divider>
    <div
      class="content-detail body-regular primaryTextColor--text mt-2"
      t-data="description"
      v-html="contentObject.description"
    ></div>
    <div class="my-4 mx-6">
      <v-btn
        v-if="dataObject.available_status"
        class="white-text-button button-bold text-capitalize"
        t-data="buy-btn"
        color="primary500Color"
        block
        style="letter-spacing: 0.0125em"
      >
        {{ buyBtnText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  calculateDiscountPrice,
  convertDateFullMonthDate,
  convertIntegerToCommaNumber
} from '@/helper/helper'
import {displayTypeEnum, packageTypeEnum} from '@/constants/package'
import SubPackageContent from '@/components/package/SubPackageContent'

export default {
  name: 'MobilePreviewContent',
  components: {
    SubPackageContent
  },
  props: {
    dataObject: {type: Object},
    orgs: {type: Array},
    lang: {type: String},
    packageType: {type: String},
    subPackageList: {type: Array}
  },
  computed: {
    isGroupPackage() {
      return this.packageType === packageTypeEnum.GROUP
    },
    hasSubPackage() {
      return this.subPackageList.length > 0
    },
    contentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code === this.lang
      })[0]
    },
    buyBtnText() {
      return this.lang === 'en' ? 'Buy Package' : 'ซื้อทันที'
    },
    displayType: () => displayTypeEnum
  },
  methods: {
    getContentByLang(contents, lang, field) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0][field]
      }
      return ''
    },
    getOrgNameByLang() {
      const fieldName = this.lang === 'en' ? 'name' : 'name_th'
      const getName = o => o.hasOwnProperty(fieldName) ? o[fieldName] : ''
      return this.orgs.map(getName).join(', ')
    },
    dateFmt(date) {
      return this.lang === 'th'
        ? convertDateFullMonthDate(date, 'th')
        : convertDateFullMonthDate(date)
    },
    commaNumber: value => convertIntegerToCommaNumber(value),
    calDiscountPrice: (discount, discountType, fullPrice) =>
      calculateDiscountPrice(discount, discountType, fullPrice),
  }
}
</script>
<style lang="scss" scoped>
.card-title {
  line-height: 22px;
}
</style>
