<template>
  <v-dialog
    v-model="show"
    :key="componentKey"
    :max-width="isWebType || isOpenWebTab ? 980 : 600"
  >
    <v-card class="px-6 py-3">
      <v-card-title class="pa-0">
        <h4 style="padding: 6px 0" t-data="headline">Quick Preview</h4>
        <v-spacer></v-spacer>
        <span t-data="image-remove-button" @click="show = false">
          <v-icon class="close-preview-button" light>
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-switch class="lang-switch d-flex justify-start" v-model="isEn" inset> </v-switch>
      <v-divider></v-divider>
      <div v-if="isMobileType">
        <div
          class="d-flex justify-center align-center py-3 body-bold secondaryTextColor--text"
        >
          Mobile Application
        </div>
        <v-divider></v-divider>
        <slot name="content-mobile" :lang="lang"></slot>
      </div>
      <div v-if="isWebType">
        <div
          class="d-flex justify-center align-center py-3 body-bold secondaryTextColor--text"
        >
          Website
        </div>
        <v-divider></v-divider>
        <slot name="content-web" :lang="lang"></slot>
      </div>
      <v-tabs
        v-if="showTab"
        v-model="activeTab"
        :key="componentTabKey"
        class="body-bold status-tab"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
        t-data="preview-tab"
        centered
      >
        <v-tab href="#MOBILE" aria-selected="false" style="min-width: 268.5px">Mobile Application</v-tab>
        <v-tab href="#WEB" aria-selected="true" style="min-width: 268.5px">Website</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" v-if="showTab">
        <v-tab-item t-data="preview-tab-item" key="MOBILE" value="MOBILE">
          <slot name="content-mobile" :lang="lang"></slot>
        </v-tab-item>
        <v-tab-item t-data="preview-tab-item" key="WEB" value="WEB">
          <slot name="content-web" :lang="lang"></slot>
        </v-tab-item>
      </v-tabs-items>

      <div class="d-flex justify-end">
        <v-btn
          v-if="showCloseBtn"
          large
          color="info600Color"
          @click="show = false"
          class="white-text-button button-bold"
          t-data="close-btn"
          text
        >
          Close
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {displayTypeEnum} from '@/constants/package'

export default {
  name: 'QuickPreview',
  props: {
    displayType: {type: String},
    isPreview: {type: Boolean},
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isEn: true,
    activeTab: displayTypeEnum.MOBILE,
    componentKey: 0,
    componentTabKey: 1,
  }),
  computed: {
    show: {
      get: function() {
        return this.isPreview
      },
      set: function(value) {
        // reset to default
        this.isEn = true
        this.activeTab = displayTypeEnum.MOBILE
        this.$emit('input', value)
      }
    },
    lang() {
      return this.isEn ? 'en' : 'th'
    },
    showTab() {
      return this.displayType === displayTypeEnum.ALL
    },
    isMobileType() {
      return this.displayType === displayTypeEnum.MOBILE
    },
    isWebType() {
      return this.displayType === displayTypeEnum.WEB
    },
    isOpenWebTab() {
      return this.activeTab === displayTypeEnum.WEB
    }
  },
  watch: {
    isPreview() {
      if (this.isPreview) this.componentKey++
    },
    activeTab() {
      this.componentTabKey++
    }
  }
}
</script>

<style scoped></style>
