<template>
  <div class="mt-6">
    <div class="d-flex justify-space-between align-center">
      <h6 class="mb-2" t-data="sub-package-header">{{ subPackageText }}</h6>
      <div v-if="isWeb">{{ pageNumberText }}</div>
    </div>
    <sub-package-list
      v-if="hasSubPackage"
      :lang="lang"
      :channel="channel"
      :sub-package-list="subPackageList"
      :available-status="availableStatus"
    ></sub-package-list>
    <div
      v-else
      class="d-flex justify-center align-center empty-container body-regular secondaryTextColor--text"
      t-data="empty-sub-package"
    >
      {{ emptySubPackageText }}
    </div>
  </div>
</template>

<script>
import SubPackageList from '@/components/package/SubPackageList'
import {displayTypeEnum, webShowItemPerPage} from '@/constants/package'
export default {
  name: 'SubPackageContent',
  components: {
    SubPackageList
  },
  props: {
    lang: {type: String},
    subPackageList: {type: Array},
    channel: {type: String},
    availableStatus: {type: Boolean,
      required: false,
      default: true
    },
  },
  computed: {
    hasSubPackage() {
      return this.subPackageList.length > 0
    },
    emptySubPackageText() {
      return this.lang === 'en' ? 'Coming soon' : 'เร็วๆนี้'
    },
    isWeb() {
      return this.channel === displayTypeEnum.WEB
    },
    pageNumberText() {
      if (this.subPackageList.length >= webShowItemPerPage) {
        return `${webShowItemPerPage}/${this.subPackageList.length}`
      } else {
        return `${this.subPackageList.length}/${this.subPackageList.length}`
      }
    },
    subPackageText() {
      if (this.channel === displayTypeEnum.WEB) {
        return this.lang === 'en' ? 'Sub package' : 'แพ็กเกจที่เหมาะสำหรับคุณ'
      } else {
        return this.lang === 'en' ? 'Sub package' : 'ตัวเลือกแพ็กเกจ'
      }
    },
    displayType: () => displayTypeEnum
  }
}
</script>

