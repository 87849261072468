<template>
  <div
    class="d-flex flex-row overflow-x-auto card-overflow"
    t-data="sub-package-content"
  >
    <v-card
      :key="`${lang}-${subPackage.id}`"
      v-for="subPackage in subPackageListByChannel"
      class="mr-4 mb-4 sub-package-card"
      :class="isWeb ? 'web-height web-max-width web-min-width' : 'mobile-height mobile-max-width mobile-min-width'"
      t-data="sub-package-card"
    >
      <v-img
        :src="getContentByLang(subPackage.contents, lang, 'thumbnail')"
        class="align-end"
        :height="isWeb ? '286px' : '87px'"
        :width="isWeb ? '295px' : ''"
        cover
      >
      </v-img>
      <v-card-title
        class="text-white card-title sub-package-card-title"
        t-data="sub-package-card-title"
      >
        <div
          class="sub-body-regular d-inline-block "
          :class="isWeb ? 'web-max-width web-sub-package-title' : 'mobile-max-width sub-package-title'"
        >
          <p class="mb-0 typographyPrimaryTextColor--text"
             :class="isWeb ? 'web-max-width web-sub-package-title' : 'mobile-max-width sub-package-title'"
             >
            {{ getContentByLang(subPackage.contents, lang, 'title') }}
          </p>
        </div>
      </v-card-title>
      <v-card-text class="pb-2 pt-0 px-2" t-data="sub-package-card-text">
        <div
          class="primary500Color--text small-bold text-truncate"
          :class="isWeb ? 'web-max-width web-price' : 'mobile-max-width'"
          t-data="sub-package-discount-price"
        >
          {{ priceText }}
          {{
            calculatePrice(
              subPackage.discount,
              subPackage.discount_type,
              subPackage.full_price
            )
          }}
          {{ isWeb ? ' .-' : ' Baht'}}
          <span
            class="sales-price-preview-discount-price small ml-1"
            :class="isWeb ? 'web-full-price' : ''"
            t-data="sub-package-full-price"
            v-if="subPackage.discount && subPackage.discount > 0"
          >
            {{ commaNumber(subPackage.full_price) }}
            {{ isWeb ? ' .-' : ' Baht'}}
          </span>
        </div>
        <div v-if="isWeb && availableStatus" class="d-flex align-center justify-center mt-12" t-data="sub-package-card-btn">
          <v-btn
            class="add-cart-btn button-bold text-capitalize mr-3"
            color="borderColor"
            outlined
            t-data="buy-btn"
            style="letter-spacing: 0.0125em;"
          >
            เพิ่มสินค้าไปยังรถเข็น
          </v-btn>
          <v-btn
            class="white-text-button button-bold text-capitalize"
            t-data="buy-btn"
            color="#2E5696"
            style="letter-spacing: 0.0125em;"
          >
            ซื้อทันที
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {displayTypeEnum} from '@/constants/package'
import {
  calculateDiscountPrice,
  convertIntegerToCommaNumber
} from '@/helper/helper'

export default {
  name: 'SubPackageList',
  props: {
    lang: {type: String},
    channel: {type: String},
    subPackageList: {type: Array},
    availableStatus: {type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {}
  },
  computed: {
    subPackageListByChannel() {
      return this.channel === displayTypeEnum.WEB ? this.subPackageList.slice(0,3) : this.subPackageList
    },
    isWeb() {
      return this.channel === displayTypeEnum.WEB
    },
    priceText() {
      if (this.channel === displayTypeEnum.WEB) {
        return 'ราคา'
      } else {
        return ''
      }
    },
  },
  methods: {
    getContentByLang(contents, lang, field) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0][field]
      }
      return ''
    },
    calculatePrice: (discount, discountType, fullPrice) =>
      calculateDiscountPrice(discount, discountType, fullPrice),
    commaNumber: value => convertIntegerToCommaNumber(value)
  }
}
</script>

<style lang="scss" scoped>
$card-width: 130px;
$card-height: 169px;

$web-card-width: 295px;
$web-card-height: 200px;

.card-title {
  line-height: 22px;
}
.sub-package-card-title {
  padding: 3px 8px 3px 8px;
}
.sub-package-title {
  min-height: 44px;
  max-height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.web-sub-package-title {
  font-size: 1.5rem;
  line-height: 45px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.mobile-max-width {
  max-width: $card-width;
}
.web-max-width {
  max-width: $web-card-width;
}
.mobile-min-width {
  min-width: $card-width;
}
.web-min-width {
  max-width: $web-card-width;
}
.mobile-max-height {
  height: $card-height;
  max-height: $card-height;
}
.web-max-height {
  height: $web-card-height;
  max-height: $web-card-height;
}
.card-overflow {
  padding-left: 1px;
}
.sub-package-card {
  box-shadow: 0px 3px 14px 2px rgba(164, 164, 172, 0.12),
    0px 8px 10px 1px rgba(164, 164, 172, 0.14),
    0px 5px 5px -3px rgba(164, 164, 172, 0.2);
}
.web-price {
  font-size: 1.125rem !important;
}
.web-full-price {
  font-size: 0.75rem !important;
}
.empty-container {
  height: $card-height;
}
.add-cart-btn {
  color: var(--typography-primary-text-color) !important;
  border: 1px solid var(--border-color);
}
</style>
